import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import LoadingSpinner from '@/components/LoadingSpinner';
import { DataRoomContextProvider } from '@/contexts/overview/dataroom/DataRoomContext';
import { RedlinesContextProvider } from '@/contexts/overview/redlines/RedLinesContext';
import { useOverview } from '@/pages/overview/common/utils';

const Redline = () => {
  const { matter, isUserCurrentEditor } = useOverview();

  const redlinesAreProcessing = useMemo(() => {
    return (
      matter?.dataRoomFiles.length === 0 ||
      matter?.dataRoomFiles.some((file) => !file.hasComputedEmbedding)
    );
  }, [matter?.dataRoomFiles]);

  if (redlinesAreProcessing) {
    return (
      <div className="flex size-full cursor-progress items-center justify-center">
        <LoadingSpinner size={20} />
      </div>
    );
  }
  return (
    <RedlinesContextProvider>
      <DataRoomContextProvider>
        <Outlet context={{ matter, isUserCurrentEditor }} />
      </DataRoomContextProvider>
    </RedlinesContextProvider>
  );
};

export default Redline;
