import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react';
import { Fragment, useCallback, useEffect, useState } from 'react';

import ColumnIcon from '@/assets/icons/columns-icon.svg';
import SearchIcon from '@/assets/icons/gray-search-icon.svg';
import {
  columnInformation,
  columnVisibilityType,
} from '@/pages/overview/contract-clauses/common/utils';

interface ColumnSelectorProps {
  columnVisibility: columnVisibilityType;
  setColumnVisibility: React.Dispatch<React.SetStateAction<columnVisibilityType>>;
}

export const ColumnSelector = ({ columnVisibility, setColumnVisibility }: ColumnSelectorProps) => {
  const [displayColumns, setDisplayedColumns] = useState<string[]>([]);

  useEffect(() => {
    const sortedColumnIds = columnInformation.map((column) => {
      return column.columnTitle;
    });

    setDisplayedColumns(sortedColumnIds);
  }, []);

  const handleColumnSearch = (searchInput: string) => {
    if (!searchInput) {
      const sortedColumnIds = columnInformation.map((column) => column.columnTitle);
      setDisplayedColumns(sortedColumnIds);
      return;
    }

    const searchedColumn = searchInput.toLowerCase();

    const filteredColumnList = columnInformation
      .map((column) => column.columnTitle)
      .filter((columnTitle) => columnTitle.toLowerCase().includes(searchedColumn));

    setDisplayedColumns(filteredColumnList);
  };

  const toggleColumnVisibility = useCallback(
    (columnId: keyof columnVisibilityType) => {
      setColumnVisibility((prev) => {
        const updatedVisibility = {
          ...prev,
          [columnId]: !prev[columnId],
        };
        return updatedVisibility as columnVisibilityType;
      });
    },
    [setColumnVisibility],
  );

  const checkVisibilityStatus = (columnId: string) => {
    const isColumnVisible = columnVisibility[columnId as keyof typeof columnVisibility];
    return isColumnVisible;
  };

  const handleHideAll = () => {
    setColumnVisibility(
      (prev) =>
        Object.fromEntries(Object.keys(prev).map((key) => [key, false])) as columnVisibilityType,
    );
  };

  const handleShowAll = () => {
    setColumnVisibility(
      (prev) =>
        Object.fromEntries(Object.keys(prev).map((key) => [key, true])) as columnVisibilityType,
    );
  };

  return (
    <>
      <Menu as="div">
        {({ open }) => (
          <div className="relative">
            <MenuButton
              className={`${open && 'bg-[#292829]'} flex h-[30px] cursor-pointer items-center justify-center gap-2 rounded border border-[#302e30] px-3 py-2.5`}
            >
              <img src={ColumnIcon} />
              <span className="text-xs font-semibold leading-snug text-[#e9e5ea]">Columns</span>
            </MenuButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems
                className="absolute z-20 mt-1 w-[274px] cursor-default rounded-[8px] border border-N700 bg-[#292829]"
                static
              >
                <div className="flex items-center gap-[10px] p-[10px]">
                  <img src={SearchIcon} />
                  <input
                    className="bg-[#292829] text-[12px] font-medium outline-none"
                    placeholder="Find column"
                    onChange={(event) => handleColumnSearch(event.target.value)}
                  />
                </div>
                {displayColumns.map((column, index) => (
                  <div key={index} className="flex items-center gap-2 px-3 py-2">
                    <input
                      type="checkbox"
                      className="size-5 cursor-pointer appearance-none rounded-[3px] border border-N300 bg-N600 bg-icon-small bg-center bg-no-repeat checked:bg-N300 checked:bg-[url('@/assets/icons/checkmark-icon.svg')] hover:bg-[#353336] checked:hover:border-N500 checked:hover:bg-N500"
                      checked={checkVisibilityStatus(column)}
                      onChange={() => toggleColumnVisibility(column as keyof columnVisibilityType)}
                    />
                    <span className="text-[12px] font-medium">{column}</span>
                  </div>
                ))}
                <div className="flex justify-between border-t border-t-N600 px-3 py-2">
                  <div
                    className="cursor-pointer text-[14px] font-medium text-N300 hover:text-N200"
                    onClick={handleShowAll}
                  >
                    Show all
                  </div>
                  <div
                    className="cursor-pointer text-[14px] font-medium text-N300 hover:text-N200"
                    onClick={handleHideAll}
                  >
                    Hide all
                  </div>
                </div>
              </MenuItems>
            </Transition>
          </div>
        )}
      </Menu>
    </>
  );
};
