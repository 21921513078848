import { useCallback, useEffect, useRef, useState } from 'react';

import RenameIcon from '@/assets/icons/edit-icon.svg';
import FileIcon from '@/assets/icons/white-file-icon.svg';
import CapTableIcon from '@/assets/images/spreadsheet-icon.svg';
import { DataRoomClientFile } from '@/common/types';
import { useContextMenu } from '@/contexts/overview/dataroom/useContextMenu';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { useOverview } from '@/pages/overview/common/utils';
import { ContextMenu } from '@/pages/overview/dataroom/content/common/ContextMenu';
import { DraggableDataRoomFile } from '@/pages/overview/dataroom/content/common/DraggableDataRoomFile';
import { MissingSignatureIndicator } from '@/pages/overview/dataroom/content/common/MissingSignatureIndicator';
import { RenameListItemForm } from '@/pages/overview/dataroom/content/common/RenameListItemForm';
import { trpcReact } from '@/utils/trpc';

interface DataRoomFileListProps {
  fileList: DataRoomClientFile[];
  dataRoomInteraction: boolean | undefined;
  folderPath: string;
  hasSubFolders: boolean;
}

interface CheckBoxProps {
  checkBoxFile: DataRoomClientFile;
  index: number;
}

export const DataRoomFileList = ({
  fileList,
  dataRoomInteraction,
  hasSubFolders,
}: DataRoomFileListProps) => {
  const [hoverIndex, setHoverIndex] = useState<number>();
  const [focusedFile, setFocusedFile] = useState<DataRoomClientFile | undefined>();
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  const [shiftKeyPressed, setShiftKeyPressed] = useState<boolean>(false);
  const renameInputRef = useRef<HTMLInputElement>(null);

  const {
    matter: {
      id: matterId,
      number: matterNumber,
      client: { number: clientNumber },
    },
    setSelectedReferenceFocus,
    isUserCurrentEditor,
  } = useOverview();

  const {
    selectedFile,
    setSelectedFile,
    selectedRenameFile,
    setSelectedRenameFile,
    checkedFiles,
    combinedDocumentArray,
    setCheckedFiles,
    isViewerOpen,
    setIsViewerOpen,
    initialClickIndex,
    setInitialClickIndex,
    goToNextDocument,
    goToPreviousDocument,
    updateSignature,
    renameFileIndex,
    setRenameFileIndex,
    isFileRenameActive,
    updateRenameFormStatus,
  } = useDataRoom();

  const { isContextMenuVisible, setIsContextMenuVisible, position, setPosition } = useContextMenu();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        setShiftKeyPressed(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        setShiftKeyPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleArrowKeys = useCallback(
    (event: KeyboardEvent) => {
      if (selectedFile && !isViewerOpen && !isFileRenameActive) {
        if (event.key === 'ArrowDown') {
          goToNextDocument();
        } else if (event.key === 'ArrowUp') {
          goToPreviousDocument();
        } else if (event.key === 'Enter') {
          setIsViewerOpen(true);
        }
      }
    },
    [
      goToNextDocument,
      goToPreviousDocument,
      isFileRenameActive,
      isViewerOpen,
      selectedFile,
      setIsViewerOpen,
    ],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleArrowKeys);

    return () => {
      document.removeEventListener('keydown', handleArrowKeys);
    };
  }, [handleArrowKeys]);

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateScreenWidth);

    return () => window.removeEventListener('resize', updateScreenWidth);
  }, []);

  fileList.sort((a, b) => {
    if (a.displayName === b.displayName) {
      return a.name.localeCompare(b.name);
    }

    return a.displayName.localeCompare(b.displayName);
  });

  const getCurrentFileIndex = useCallback(
    (currentFile: DataRoomClientFile | undefined) => {
      const currentFileIndex = combinedDocumentArray.findIndex(
        (dataRoomFile) => dataRoomFile.name === currentFile?.name,
      );
      return currentFileIndex;
    },
    [combinedDocumentArray],
  );

  const handleShiftKeyPressed = (start: number, end: number) => {
    let filesToSelect: DataRoomClientFile[] = [];

    if (start > end) {
      filesToSelect = combinedDocumentArray.slice(end, start);
    } else {
      filesToSelect = combinedDocumentArray.slice(start + 1, end + 1);
    }

    const uniqueFilesToSelect = filesToSelect.filter((file) => !checkedFiles.includes(file));

    setCheckedFiles([...checkedFiles, ...uniqueFilesToSelect]);
  };

  const handleDefaultClick = (file: DataRoomClientFile) => {
    if (checkedFiles.some((checkedFile) => checkedFile.name === file.name)) {
      setCheckedFiles(checkedFiles.filter((checkedFile) => checkedFile.name !== file.name));
    } else {
      setInitialClickIndex(
        combinedDocumentArray.findIndex((dataRoomFile) => dataRoomFile.name === file.name),
      );
      setCheckedFiles([...checkedFiles, file]);
      setSelectedFile(file.name);
    }
  };

  const toggleCheckbox = (file: DataRoomClientFile) => {
    if (shiftKeyPressed) {
      const start = initialClickIndex;
      const end = getCurrentFileIndex(focusedFile);
      handleShiftKeyPressed(start, end);
    } else {
      handleDefaultClick(file);
    }
  };

  const CheckBox = ({ checkBoxFile }: CheckBoxProps) => {
    const isFileChecked = checkedFiles.some(
      (checkedFile) => checkedFile.name === checkBoxFile.name,
    );
    return (
      <div className="flex items-center">
        {hoverIndex === getCurrentFileIndex(checkBoxFile) || isFileChecked ? (
          <input
            type="checkbox"
            checked={isFileChecked}
            onChange={() => toggleCheckbox(checkBoxFile)}
            className={`${!isFileChecked && 'hover:bg-N600'} size-[12px] cursor-pointer appearance-none rounded-[2px] border border-marveri-white  bg-contain bg-center bg-no-repeat checked:border-transparent checked:bg-[url('@/assets/images/check-icon-white.svg')] checked:filter-dark`}
            data-testid="data-room-file-checkbox"
          />
        ) : (
          <div className="size-[12px]"></div>
        )}
      </div>
    );
  };

  const fileToRename = trpcReact.dataRoom.updateDataRoomFileName.useMutation();
  const updateFileName = (file: DataRoomClientFile, newName: string) => {
    if (newName === '') {
      newName = file.displayName;
    }
    fileToRename.mutate({
      name: file.name,
      clientNumber: clientNumber,
      clientMatterNumber: matterNumber,
      newName: newName,
      clientMatterId: matterId,
    });

    updateRenameFormStatus(false);
  };

  const handleMouseEnter = useCallback(
    (index: number, file: DataRoomClientFile) => {
      if (isFileRenameActive) return;
      setHoverIndex(getCurrentFileIndex(file));
      if (isContextMenuVisible) return;
      setFocusedFile(file);
    },
    [getCurrentFileIndex, isContextMenuVisible, isFileRenameActive],
  );

  const handleMouseLeave = () => {
    if (isFileRenameActive) return;
    setHoverIndex(undefined);
  };

  const handleFileClick = (file: DataRoomClientFile) => {
    setRenameFileIndex(undefined);
    updateRenameFormStatus(false);
    setSelectedFile(file.name);
  };

  const handleDoubleClick = () => {
    if (isFileRenameActive) return;
    setSelectedReferenceFocus('referenced');
    setIsViewerOpen(true);
  };

  const handleRightClick = (e: React.MouseEvent, file: DataRoomClientFile) => {
    if (!dataRoomInteraction) return;
    e.preventDefault();
    setFocusedFile(file);
    setIsContextMenuVisible(true);
    setPosition({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const handleMouseLeaveFileList = () => {
    setIsContextMenuVisible(false);
  };

  const handleRenameClick = (e: React.MouseEvent<Element>, file: DataRoomClientFile) => {
    e.stopPropagation();
    updateRenameFormStatus(true);
    setSelectedRenameFile(file);
  };

  const handleRenameFormClose = () => {
    setRenameFileIndex(undefined);
    updateRenameFormStatus(false);
  };

  const checkFileExtenstion = (filename: string) => {
    const fileExtension = filename.split('.').pop();

    if (fileExtension === 'xlsx') {
      return true;
    }

    return false;
  };

  const truncateDisplayName = useCallback(
    (fileDisplayName: string) => {
      if (screenWidth <= 1440) {
        if (fileDisplayName.length <= 150) {
          return fileDisplayName;
        }
        const start = fileDisplayName.slice(0, 55);
        const end = fileDisplayName.slice(-20);
        return `${start} ... ${end}`;
      } else {
        return fileDisplayName;
      }
    },
    [screenWidth],
  );

  if (fileList.length <= 0 && !hasSubFolders) {
    return (
      <div>
        <div className="flex gap-[14px] px-[35px] py-[12px] pl-[70px]">
          <span>
            <i>(Empty)</i>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div onMouseLeave={handleMouseLeaveFileList}>
      {fileList.map((file, index) => (
        <div
          id={file.name}
          key={file.name}
          className={`${renameFileIndex === getCurrentFileIndex(file) && 'bg-[#2e2e2e]'} ${
            checkedFiles.some((checkedFile) => checkedFile.name === file.name)
              ? 'bg-[#A9A9A9] text-[#121212]'
              : 'hover:bg-[#292929]'
          } ${selectedFile && selectedFile.name === file.name && 'bg-[#292929] ring-2 ring-inset ring-marveri-gold'} 
          ${index === 0 && 'border-none'} ${index === fileList.length - 1 && 'rounded-b-[4px]'} flex items-center border-t border-[#292929] pl-[30px] pr-[12px]`}
          onMouseEnter={() => handleMouseEnter(index, file)}
          onMouseLeave={handleMouseLeave}
          data-testid="data-room-file-list-item"
        >
          <CheckBox checkBoxFile={file} index={index} />
          <div className="ml-3 w-full">
            <DraggableDataRoomFile
              key={file.name}
              draggableId={file.name}
              currentDroppableId={file.path}
              disabled={isFileRenameActive || !isUserCurrentEditor}
            >
              <div
                className={`relative flex h-[50px] w-full cursor-pointer items-center gap-[14px] py-[12px] text-[14px] font-medium`}
                key={file.name}
                onClick={() => handleFileClick(file)}
                onContextMenu={(e) => handleRightClick(e, file)}
                onDoubleClick={handleDoubleClick}
              >
                <div className="flex w-full items-center gap-[12px]">
                  {checkFileExtenstion(file.name) ? (
                    <img src={CapTableIcon} alt="file-icon" className="h-[13px]" />
                  ) : (
                    <img
                      src={FileIcon}
                      alt="file-icon"
                      className={`${checkedFiles.some((checkedFile) => checkedFile.name === file.name) && 'filter-dark'} h-[13px]`}
                    />
                  )}
                  {isFileRenameActive && selectedRenameFile?.name === file.name ? (
                    <RenameListItemForm
                      onClose={handleRenameFormClose}
                      onSubmit={(newName) => updateFileName(selectedRenameFile, newName)}
                      currentName={selectedRenameFile?.displayName}
                      renameInputRef={renameInputRef}
                      setRenameFileIndex={setRenameFileIndex}
                    />
                  ) : (
                    <span className="flex break-words text-left text-[14px] tracking-[0.14px]">
                      {truncateDisplayName(file.displayName)}
                    </span>
                  )}
                  {hoverIndex === getCurrentFileIndex(file) &&
                    !isFileRenameActive &&
                    dataRoomInteraction && (
                      <MarveriIcon
                        icon={RenameIcon}
                        onClick={(event) => handleRenameClick(event, file)}
                        iconStyle="shrink-0 z-20 translate-y-[4px]"
                        iconType="list-main"
                        disabled={!isUserCurrentEditor}
                      />
                    )}
                  {file.missingSignature === true && (
                    <MissingSignatureIndicator
                      fileName={file.name}
                      focusedFileName={selectedRenameFile?.name}
                      isRenameActive={isFileRenameActive}
                      containerStyle="ml-auto shrink-0"
                      onClick={() => updateSignature(file.name, false)}
                      disabled={!isUserCurrentEditor}
                    />
                  )}
                </div>
              </div>
            </DraggableDataRoomFile>
          </div>
        </div>
      ))}
      {isContextMenuVisible && (
        <ContextMenu focusedFile={focusedFile} xPosition={position.x} yPosition={position.y} />
      )}
    </div>
  );
};
