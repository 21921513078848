import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { useCallback, useState } from 'react';

import ExitIcon from '@/assets/icons/gray-x-icon.svg';
import UserIcon from '@/assets/icons/user-icon-default.svg';
import { useOverview } from '@/pages/overview/common/utils';
import { showSuccessToast } from '@/utils/toasts/ToastSuccess';
import { trpcReact } from '@/utils/trpc';

interface InviteUserConfirmationProps {
  isInviteConfirmationOpen: boolean;
  setIsInviteConfirmationOpen: (val: boolean) => void;
  setIsInviteModalOpen: (val: boolean) => void;
  sharedEmails: string[];
  noteInputValue: string;
}

export const InviteUserConfirmation = ({
  isInviteConfirmationOpen,
  setIsInviteConfirmationOpen,
  setIsInviteModalOpen,
  sharedEmails,
  noteInputValue,
}: InviteUserConfirmationProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const {
    matter: {
      number: matterNumber,
      client: { number: clientNumber },
      id: clientMatterId,
      users,
    },
  } = useOverview();

  const inviteAdmins = trpcReact.dataRoom.inviteAdminToDocumentSet.useMutation({
    onSuccess: () => {
      showSuccessToast(`Invite sent to ${sharedEmails.length} user(s)`);
      setIsInviteModalOpen(false);
      setIsInviteConfirmationOpen(false);
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
  });

  const clickBack = useCallback(() => {
    setErrorMessage('');
    setIsInviteConfirmationOpen(false);
  }, [setIsInviteConfirmationOpen]);

  const clickInvite = useCallback(() => {
    const checkExistingEmail = users.some((user) => sharedEmails.includes(user.email));
    if (checkExistingEmail) {
      setErrorMessage('One or more users have already been invited');
      return;
    }
    inviteAdmins.mutate({
      clientNumber: clientNumber,
      clientMatterNumber: matterNumber,
      emails: sharedEmails,
      clientMatterId: clientMatterId,
      note: noteInputValue,
    });
  }, [
    users,
    inviteAdmins,
    clientNumber,
    matterNumber,
    sharedEmails,
    clientMatterId,
    noteInputValue,
  ]);

  return (
    <Dialog
      open={isInviteConfirmationOpen}
      onClose={() => setIsInviteConfirmationOpen(false)}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto"
      data-testid="invite-user-modal"
    >
      <DialogPanel className={`relative w-[592px] rounded-lg bg-[#121112] p-8 text-marveri-white`}>
        <DialogTitle className={`mb-8 flex justify-between text-[24px]`}>
          <span className="font-medium">Are you sure?</span>
          <div
            className="cursor-pointer hover:filter-hover-filter"
            onClick={() => setIsInviteConfirmationOpen(false)}
          >
            <img src={ExitIcon} />
          </div>
        </DialogTitle>
        <div className="mb-6">
          <h2 className="text-[14px] font-medium text-N400">
            You are about to grant the following users admin rights.
          </h2>
          <h2 className="text-[14px] font-medium text-N400">
            They will be able to see and edit all pages and tools of the document set.
          </h2>
        </div>
        <div className="mb-6 border-t border-[#353336] pl-[16px] pr-[12px] pt-[7.5px]">
          {sharedEmails.map((email, index) => {
            return (
              <div key={index} className="flex items-center justify-between py-2">
                <div className="flex gap-3 py-[7.5px]">
                  <img src={UserIcon} /> <span>{email}</span>
                </div>
                <div className="flex items-center rounded-[28px] bg-[#292829] px-3 py-2">Admin</div>
              </div>
            );
          })}
        </div>
        <div className="mb-[32px]">
          {noteInputValue.length > 0 && (
            <div className="w-full rounded-[4px] bg-[#19181A] px-[16px] py-[10px] text-N400">{`"${noteInputValue}"`}</div>
          )}
          {errorMessage && <span className="text-[14px] text-[#CF212E]">{errorMessage}</span>}
        </div>
        <div className="flex w-full justify-end gap-2 text-[14px]">
          <div
            className="flex h-[40px] w-[73px] cursor-pointer items-center justify-center rounded-full px-[18px] py-[16px] hover:bg-[#292829]"
            onClick={clickBack}
          >
            Back
          </div>
          <div
            className={`${sharedEmails.length > 0 ? 'cursor-pointer bg-marveri-white text-[#121112] hover:bg-N200 ' : 'cursor-default bg-[#353336] text-N500'} flex h-[40px] w-fit items-center rounded-full px-[18px] py-[16px]`}
            onClick={clickInvite}
          >
            Yes, Invite
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  );
};
