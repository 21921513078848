import { useMemo } from 'react';

import { DataRoomContextProvider } from '@/contexts/overview/dataroom/DataRoomContext';
import { NoFilesFound } from '@/pages/overview/common/NoFilesFound';
import { OverviewPageLoader } from '@/pages/overview/common/OverviewPageLoader';
import { useOverview } from '@/pages/overview/common/utils';
import { ContractClausesContent } from '@/pages/overview/contract-clauses/content/ContractClausesContent';

export const ContractClauses = () => {
  const { matter } = useOverview();

  const dataRoomFiles = useMemo(() => {
    return matter?.dataRoomFiles;
  }, [matter]);

  const hasContractDataProcessing = useMemo(() => {
    return (
      matter?.dataRoomFiles.length === 0 ||
      matter?.dataRoomFiles.some((file) => !file.maClausesProcessed)
    );
  }, [matter?.dataRoomFiles]);

  const hasContractData = useMemo(() => {
    const totalContracts = dataRoomFiles.flatMap((file) =>
      file.maClausesData.map((contractClause) => {
        if (contractClause) {
          return Array.isArray(contractClause.maClauses) ? contractClause.maClauses.length : 0;
        } else {
          return 0;
        }
      }),
    );

    return totalContracts.some((length) => length > 0);
  }, [dataRoomFiles]);

  return (
    <DataRoomContextProvider>
      {hasContractDataProcessing ? (
        <OverviewPageLoader pageFocusTitle="Identifying Contract Clauses" />
      ) : hasContractData ? (
        <ContractClausesContent />
      ) : (
        <NoFilesFound pageFocus="Contract Clauses" />
      )}
    </DataRoomContextProvider>
  );
};
