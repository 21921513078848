import { Dialog, DialogPanel } from '@headlessui/react';
import { useCallback, useEffect, useRef, useState } from 'react';

import Xicon from '@/assets/icons/gray-x-icon.svg';
import { User } from '@/common/types';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { NamingTemplate } from '@/pages/settings/namingTemplate/NamingTemplate';
import { Profile } from '@/pages/settings/profile/Profile';
import { Security } from '@/pages/settings/security/Security';
import { UnsavedChangesModal } from '@/pages/settings/UnsavedChangesModal';

interface ActiveSettingsTabProps {
  tabName: string;
  focusedTab: string;
  onClick: (val: string) => void;
}

export const ActiveSettingsTabLink = ({ tabName, focusedTab, onClick }: ActiveSettingsTabProps) => {
  const handleTabClick = () => {
    onClick(tabName);
  };
  return (
    <div
      className={`${tabName === focusedTab && ' bg-N600'} h-[60px] w-[240px] cursor-pointer rounded-[8px] px-[16px] py-[20px] font-semibold text-[#EAEAEA] hover:bg-N700`}
      onClick={handleTabClick}
      data-testid="active-settings-tab"
    >
      {tabName}
    </div>
  );
};

interface SettingProps {
  isSettingsModalOpen: boolean;
  setIsSettingModalOpen: (val: boolean) => void;
  anchorTab?: string;
  setSettingsAnchorTag?: (val: string) => void;
  user: User;
}

export const Settings = ({
  user,
  isSettingsModalOpen,
  setIsSettingModalOpen,
  anchorTab,
  setSettingsAnchorTag,
}: SettingProps) => {
  const settingsNavigation = [
    {
      tab: 'Profile Settings',
      path: 'profile',
    },
    { tab: 'Document Naming', path: 'doc-naming' },
    {
      tab: 'Security & Privacy',
      path: 'security-privacy',
    },
  ];

  const [focusedTab, setFocusedTab] = useState('Profile Settings');
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] = useState(false);
  const [isScrollActive, setIsScrollActive] = useState(false);
  const [canClose, setCanClose] = useState(true);

  const profileRef = useRef<HTMLDivElement>(null);
  const docNamingRef = useRef<HTMLDivElement>(null);
  const securityRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollToTab = (tabName: string) => {
    setIsScrollActive(true);
    if (tabName === 'Profile Settings' && profileRef.current) {
      setFocusedTab('Profile Settings');
      profileRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (tabName === 'Document Naming' && docNamingRef.current) {
      setFocusedTab('Document Naming');
      docNamingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (tabName === 'Security & Privacy' && securityRef.current) {
      setFocusedTab('Security & Privacy');
      securityRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    setTimeout(() => {
      setIsScrollActive(false);
    }, 500);
  };

  const handleScroll = useCallback(() => {
    if (!isScrollActive && scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
      if (scrollPercentage < 30) {
        setFocusedTab('Profile Settings');
      }
      if (scrollPercentage > 30 && scrollPercentage < 75) {
        setFocusedTab('Document Naming');
      }
      if (scrollPercentage > 75) {
        setFocusedTab('Security & Privacy');
      }
    }
  }, [isScrollActive]);

  useEffect(() => {
    if (anchorTab) {
      if (anchorTab === 'Document Naming') {
        setTimeout(() => {
          scrollToTab('Document Naming');
        }, 100);
      }
    }
  }, [anchorTab]);
  const canCloseCallback = useCallback((newCanClose: boolean) => {
    setCanClose(newCanClose);
  }, []);

  const checkIfSettingsCanClose = () => {
    if (!canClose) {
      setIsUnsavedChangesModalOpen(true);

      return;
    }
    setIsSettingModalOpen(false);
    setFocusedTab('Profile Settings');
    if (setSettingsAnchorTag) {
      setSettingsAnchorTag('Profile Settings');
    }
  };

  return (
    <Dialog
      open={isSettingsModalOpen}
      onClose={checkIfSettingsCanClose}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50"
      data-testid="settings-page-modal"
    >
      <DialogPanel className="max-h-[667px] min-h-[352px] w-[70%] rounded-lg bg-[#121212] py-[32px] pr-[32px] text-marveri-white xlarge:max-h-[627px]">
        <div className="flex justify-between pl-[32px]">
          <h1 className="text-[30px] font-semibold ">Settings</h1>
          <MarveriIcon
            icon={Xicon}
            onClick={checkIfSettingsCanClose}
            iconStyle=""
            iconType="other"
          />
        </div>
        <div className="flex w-full">
          <div className="flex flex-col gap-[4px] px-[18px] py-[32px]">
            {settingsNavigation.map((settingNav, index) => {
              return (
                <ActiveSettingsTabLink
                  key={index}
                  tabName={settingNav.tab}
                  focusedTab={focusedTab}
                  onClick={() => scrollToTab(settingNav.tab)}
                />
              );
            })}
          </div>
          <ScrollableDiv
            containerStyle="pb-1 flex flex-col w-full medium:px-[18px] px-[60px] h-[calc(30vh+100px)]"
            ref={scrollRef}
            onScroll={handleScroll}
          >
            <div ref={profileRef}>
              <Profile />
            </div>
            <div className="my-[48px] w-full border border-[#303030]"></div>
            <div ref={docNamingRef}>
              <NamingTemplate user={user} setCanClose={canCloseCallback} />
            </div>
            <div className="my-[48px] w-full border border-[#303030]"></div>
            <div ref={securityRef}>
              <Security />
            </div>
          </ScrollableDiv>
        </div>
      </DialogPanel>
      <UnsavedChangesModal
        isUnsavedChangesModalOpen={isUnsavedChangesModalOpen}
        setIsUnsavedChangesModalOpen={setIsUnsavedChangesModalOpen}
        setIsSettingModalOpen={setIsSettingModalOpen}
      />
    </Dialog>
  );
};
