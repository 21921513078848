import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo } from 'react';

import { toolList } from '@/pages/home/common/toolList';
import { useOverview } from '@/pages/overview/common/utils';
import { ToolPageCard } from '@/pages/tools/common/ToolPageCard';

export const ToolPageContent = () => {
  const { matter } = useOverview();
  const flags = useFlags();

  const userSelectedTools = useMemo(() => {
    return matter.tools.map((tool) => tool.toolType);
  }, [matter.tools]);

  const dataRoomTitle = useMemo(() => {
    return matter.name;
  }, [matter]);

  const checkIfToolSelected = useCallback(
    (toolIdentifier: string) => {
      if (userSelectedTools) {
        return userSelectedTools.includes(toolIdentifier);
      } else {
        return false;
      }
    },
    [userSelectedTools],
  );

  const creatableOrExistingTools = useMemo(() => {
    return toolList
      .filter((tool) => tool.creatable || userSelectedTools.includes(tool.toolIdentifier))
      .filter(
        (tool) =>
          flags.missingDocColumnSelector || tool.toolIdentifier !== 'MISSING_DOCUMENT_DEBUG',
      );
  }, [flags.missingDocColumnSelector, userSelectedTools]);

  return (
    <div className="w-full rounded-[12px] bg-[#121112] px-[16px] pb-[20px] pt-[108px]">
      <h1 className="pb-[16px] text-[24px] text-marveri-white">Add Tools to {dataRoomTitle}</h1>

      <div className="grid grid-cols-[repeat(auto-fit,_minmax(230px,_1fr))] gap-3">
        {creatableOrExistingTools.map((tool, index) => {
          return (
            <ToolPageCard
              key={index}
              toolName={tool.name}
              toolIcon={tool.icon}
              toolIdentifier={tool.toolIdentifier}
              toolReadyQuote={tool.toolReadyQuote}
              toolTip={tool.toolTip}
              path={tool.path}
              isToolAdded={checkIfToolSelected(tool.toolIdentifier)}
            />
          );
        })}
      </div>
    </div>
  );
};
