import { DataRoomContextProvider } from '@/contexts/overview/dataroom/DataRoomContext';
import { DataRoomContentLayout } from '@/pages/overview/dataroom/DataRoomContentLayout';

export const DataRoom = () => {
  return (
    <DataRoomContextProvider>
      <DataRoomContentLayout />
    </DataRoomContextProvider>
  );
};
