interface ReferenceTotalTagProps {
  totalReferences: number | undefined;
  containerStyle: string;
}

export const ReferenceTotalTag = ({ totalReferences, containerStyle }: ReferenceTotalTagProps) => {
  return (
    <div
      className={`${containerStyle} flex h-[15px] w-[18px] items-center justify-center rounded-[28px] text-[12px] font-semibold`}
    >
      <span>{totalReferences}</span>
    </div>
  );
};
