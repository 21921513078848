import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { trpcReact } from '@/utils/trpc';

export const ClientMatterRedirect = () => {
  const { clientNumber, matterNumber } = useParams();
  const navigate = useNavigate();

  const convertParamToInt = useCallback(() => {
    if (clientNumber === undefined || matterNumber === undefined) {
      throw new Error('clientNumber and matterNumber must be defined.');
    }
    const clientNumberInt = parseInt(clientNumber);
    const matterNumberInt = parseInt(matterNumber);
    return [clientNumberInt, matterNumberInt];
  }, [clientNumber, matterNumber]);

  const [userClientNumber, userMatterNumber] = convertParamToInt();

  trpcReact.dataRoom.getDeprecated.useQuery(
    {
      number: userMatterNumber,
      clientNumber: userClientNumber,
    },
    {
      onSuccess: (ret) => {
        navigate(`/${ret.id}/dataroom`);
      },
      onError: () => {
        navigate(`/home`);
      },
    },
  );

  return <></>;
};
