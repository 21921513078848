import { useCallback, useEffect, useMemo, useState } from 'react';

import Refresh from '@/assets/icons/refresh.svg';
import Success from '@/assets/icons/success.svg';
import Warning from '@/assets/icons/warning.svg';
import { User } from '@/common/types';
import { formatFileDisplay, FormatTemplate } from '@/pages/overview/common/utils';
import { DragNDropTemplate } from '@/pages/settings/namingTemplate/DragNDropTemplate';
import { showSuccessToast } from '@/utils/toasts/ToastSuccess';
import { trpcReact } from '@/utils/trpc';

const DEFAULT_TEMPLATE_VALUE =
  '<<date::YYYY.MM.DD::>> <<company_name>> - <<document_title>> (<<counterparties>>)';

interface NamingTemplateProps {
  setCanClose: (canClose: boolean) => void;
  user: User;
}

export interface InputStateType {
  saveStatus: 'SAVED' | 'UNSAVED' | 'SAVING' | 'RESAVED';
  validationStatus: 'VALID' | 'INVALID_CHARACTER' | 'INVALID_LENGTH' | 'INVALID_TERMINAL';
}

export const NamingTemplate = ({ user, setCanClose }: NamingTemplateProps) => {
  const [newTemplate, setNewTemplate] = useState<string>(
    user.namingTemplate || DEFAULT_TEMPLATE_VALUE,
  );
  const [exampleText, setExampleText] = useState<string>('');
  const [templateKey, setTemplateKey] = useState(0);

  const [selectedDateFormat, setSelectedDateFormat] = useState('YYYY.MM.DD' as FormatTemplate);

  const [inputState, setInputState] = useState<InputStateType>({
    saveStatus: 'SAVED',
    validationStatus: 'VALID',
  });

  useEffect(() => {
    setNewTemplate(user?.namingTemplate || DEFAULT_TEMPLATE_VALUE);
    if (user !== null && user.namingTemplate !== null) {
      let date = 'YYYY.MM.DD';
      const match = user.namingTemplate.match(/<<date::.*::>>/);
      if (match) {
        date = match[0].slice(8, -4);
      }
      setSelectedDateFormat(date as FormatTemplate);
    }
    setInputState({
      saveStatus: 'SAVED',
      validationStatus: 'VALID',
    });
    setCanClose(true);
    setExampleText(
      formatFileDisplay(
        {
          date: new Date('2020-06-15T12:00:00Z'),
          company: 'Certus, Inc.',
          docTitle: 'Convertible Promissory Note',
          subject: ['Yardstick VC, LLC'],
          name: 'Does not matter.pdf',
        },
        user?.namingTemplate || DEFAULT_TEMPLATE_VALUE,
      ),
    );
  }, [setCanClose, setInputState, user]);

  const updateTemplate = trpcReact.user.updateNamingTemplate.useMutation();

  const saveNewTemplate = useCallback(() => {
    setInputState((inputState) => {
      return {
        ...inputState,
        saveStatus: 'SAVING',
      };
    });
    updateTemplate.mutate(
      { newTemplate },
      {
        onSuccess: () => {
          setInputState({
            saveStatus: 'RESAVED',
            validationStatus: 'VALID',
          });
          setCanClose(true);
          showSuccessToast('Naming convention saved. Re-run Rename tool to apply.', 'w-[450px]');
        },
      },
    );
  }, [newTemplate, setCanClose, updateTemplate]);

  const handleValidation = useCallback(
    (newValue: string) => {
      const testTemplate = newValue.replaceAll(/<<.+?>>/g, '');
      if ((testTemplate.endsWith('.') || testTemplate.endsWith(' ')) && !newValue.endsWith('>>')) {
        setInputState((inputState) => {
          return {
            ...inputState,
            validationStatus: 'INVALID_TERMINAL',
          };
        });
      } else if (
        ['<', '>', ':', '"', '/', '\\', '|', '?', '*'].some((char) => testTemplate.includes(char))
      ) {
        setInputState((inputState) => {
          return {
            ...inputState,
            validationStatus: 'INVALID_CHARACTER',
          };
        });
      } else if (testTemplate.length > 30) {
        setInputState((inputState) => {
          return {
            ...inputState,
            validationStatus: 'INVALID_LENGTH',
          };
        });
      } else {
        setInputState((inputState) => {
          return {
            ...inputState,
            validationStatus: 'VALID',
          };
        });
        setExampleText(
          formatFileDisplay(
            {
              date: new Date('2020-06-15T12:00:00Z'),
              company: 'Certus, Inc.',
              docTitle: 'Convertible Promissory Note',
              subject: ['Yardstick VC, LLC'],
              name: 'Does not matter.pdf',
            },
            newValue,
          ),
        );
      }
    },
    [setInputState],
  );

  const handleResetInput = useCallback(() => {
    setNewTemplate(DEFAULT_TEMPLATE_VALUE);
    setExampleText(
      formatFileDisplay(
        {
          date: new Date('2020-06-15T12:00:00Z'),
          company: 'Certus, Inc.',
          docTitle: 'Convertible Promissory Note',
          subject: ['Yardstick VC, LLC'],
          name: 'Does not matter.pdf',
        },
        DEFAULT_TEMPLATE_VALUE,
      ),
    );
    setInputState({
      ...inputState,
      saveStatus: 'UNSAVED',
    });
    setTemplateKey((prevKey) => prevKey + 1);
  }, [inputState]);

  useEffect(() => {
    setNewTemplate((oldTemplate) => {
      const tempTemplate = oldTemplate.replace(
        /<<date::.*::>>/g,
        `<<date::${selectedDateFormat}::>>`,
      );
      if (tempTemplate !== oldTemplate) {
        setInputState((inputState) => {
          return {
            ...inputState,
            saveStatus: 'UNSAVED',
          };
        });
      }
      handleValidation(tempTemplate);
      return tempTemplate;
    });
  }, [handleValidation, selectedDateFormat, setInputState]);

  const saveText = useMemo(() => {
    return {
      SAVED: (
        <>
          <img src={Success} className="h-[15px] w-[13px]" />
          Saved
        </>
      ),
      UNSAVED: (
        <>
          <img src={Warning} className="h-[15px] w-[13px]" />
          Unsaved
        </>
      ),
      SAVING: (
        <>
          <img src={Refresh} className="h-[15px] w-[13px]" />
          Saving...
        </>
      ),
      RESAVED: (
        <>
          <img src={Success} className="h-[15px] w-[13px]" />
          Saved - Run Rename tool to apply
        </>
      ),
    }[inputState.saveStatus];
  }, [inputState.saveStatus]);

  const validationText = useMemo(() => {
    return {
      VALID: null,
      INVALID_TERMINAL: (
        <span className="text-[#FF3B30]">
          Oops! It looks like that character isn&apos;t allowed at the end of the name.
        </span>
      ),
      INVALID_CHARACTER: (
        <span className="text-[#FF3B30]">
          Oops! It looks like that character isn&apos;t allowed.
        </span>
      ),
      INVALID_LENGTH: (
        <span className="text-[#FF3B30]">
          Oops! It looks like there are too many extra characters.
        </span>
      ),
    }[inputState.validationStatus];
  }, [inputState.validationStatus]);

  const saveDisabled = useMemo(() => {
    return inputState.saveStatus !== 'UNSAVED' || inputState.validationStatus !== 'VALID';
  }, [inputState.saveStatus, inputState.validationStatus]);

  const resetInputDisabled = useMemo(() => {
    return newTemplate === DEFAULT_TEMPLATE_VALUE;
  }, [newTemplate]);

  const colorMap = useMemo(() => {
    return {
      'Certus, Inc.': 'text-[#32ADE6]',
      '2020.06.16': 'text-[#00C7BE]',
      '2020-06-16': 'text-[#00C7BE]',
      'June 16, 2020': 'text-[#00C7BE]',
      '16 June 2020': 'text-[#00C7BE]',
      '06-16-2020': 'text-[#00C7BE]',
      'Convertible Promissory Note': 'text-[#5856D6]',
      'Yardstick VC, LLC': 'text-[#FF2D55]',
    } as { [key: string]: string };
  }, []);

  const highlightedExampleText = useMemo(() => {
    let splittable = exampleText;
    const substrings = Object.keys(colorMap);
    substrings.forEach((substring) => {
      splittable = splittable.replace(substring, '<<<' + substring + '<<<');
    });
    return splittable.split('<<<');
  }, [colorMap, exampleText]);

  return (
    <div className="cursor-default" data-testid="document-naming">
      <div className="flex gap-2">
        <h1 className="mb-[16px] text-[18px] font-semibold">Document Naming Convention</h1>{' '}
      </div>
      <div className="mb-[16px] text-[12px] text-N200">
        Customize your naming convention by editing the fields below or dragging cateogry names.
      </div>
      <div className="flex flex-col gap-[16px]">
        <div className="flex flex-row whitespace-pre text-black">
          {highlightedExampleText.map((frag, idx) => {
            return (
              <span className={colorMap[frag] ?? 'text-white'} key={`fragment-${idx}`}>
                {frag}
              </span>
            );
          })}
        </div>
        <div className="relative flex gap-[4px] text-marveri-white">
          <div className="flex w-full flex-col gap-2">
            <DragNDropTemplate
              key={templateKey}
              newTemplate={newTemplate}
              setNewTemplate={setNewTemplate}
              setExampleText={setExampleText}
              inputState={inputState}
              setInputState={setInputState}
              setCanClose={setCanClose}
              selectedDateFormat={selectedDateFormat}
              setSelectedDateFormat={setSelectedDateFormat}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-row gap-[4px] text-[10px] text-N400">
            {validationText ?? saveText}
          </div>
          <div className="flex flex-row items-end gap-[4px] ">
            <button
              className={`${resetInputDisabled ? 'cursor-not-allowed text-N600' : 'cursor-pointer text-[#EAE6EA]'} h-[38px] w-[69px] rounded-[30px] bg-N700 text-[14px]`}
              disabled={resetInputDisabled}
              onClick={handleResetInput}
            >
              Reset
            </button>
            <button
              className={`h-[38px] w-[69px] ${saveDisabled ? 'cursor-not-allowed text-N600' : 'cursor-pointer text-[#EAE6EA]'} rounded-[30px] bg-N700 text-[14px] `}
              disabled={saveDisabled}
              onClick={saveNewTemplate}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
