import { FileWithPath } from 'file-selector';
import { useCallback, useMemo, useState } from 'react';
import { useFilePicker } from 'use-file-picker';

import FileIcon from '@/assets/icons/file-upload.svg';
import FolderIcon from '@/assets/icons/folder-upload.svg';
import NewFolder from '@/assets/icons/new-folder.svg';
import AddNewIcon from '@/assets/images/add-new-icon.svg';
import { ACCEPTED_TYPES } from '@/common/utils';
import { Dropdown } from '@/components/Dropdown';
import { useOverview } from '@/pages/overview/common/utils';
import {
  ConfirmationDialog,
  DialogViewState,
} from '@/pages/overview/dataroom/content/common/ConfirmationDialog';
import { ConfirmNewFolderDialog } from '@/pages/overview/dataroom/content/common/ConfirmNewFolderDialog';
import { uploadNewDataRoomFiles } from '@/pages/overview/dataroom/content/common/utils';

interface DataRoomAddNewProps {
  clientMatterNumber: number;
  clientNumber: number;
  clientMatterId: string;
  dataRoomFileNames: string[];
}

export const DataRoomAddNew = ({
  clientMatterNumber,
  clientNumber,
  clientMatterId,
  dataRoomFileNames,
}: DataRoomAddNewProps) => {
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [isFolderDialogOpen, setIsFolderDialogOpen] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<FileWithPath[]>([]);

  const [viewState, setViewState] = useState<DialogViewState>('ready');

  const { isUserCurrentEditor } = useOverview();

  const { openFilePicker } = useFilePicker({
    readAs: 'DataURL',
    accept: ACCEPTED_TYPES,
    multiple: true,
    onFilesSuccessfullySelected: async ({ plainFiles }) => {
      setFilesToUpload(plainFiles);
      setViewState('ready');
      setIsUploadDialogOpen(true);
    },
  });

  const closeDialog = useCallback(() => {
    setIsUploadDialogOpen(false);
    setIsFolderDialogOpen(false);
  }, []);

  const reset = useCallback(() => {
    setFilesToUpload([]);
    closeDialog();
  }, [closeDialog]);

  const onUploadSubmit = useCallback(async () => {
    setViewState('submitting');

    try {
      await uploadNewDataRoomFiles(
        filesToUpload,
        clientMatterNumber,
        clientNumber,
        clientMatterId,
        dataRoomFileNames,
      );
      reset();
    } catch (e) {
      setFilesToUpload([]);
      setViewState('errored');
    }
  }, [clientMatterId, clientMatterNumber, clientNumber, dataRoomFileNames, filesToUpload, reset]);

  const options = useMemo(() => {
    return [
      {
        title: 'File upload',
        icon: FileIcon,
        onClick: openFilePicker,
        disabled: !isUserCurrentEditor,
      },
      {
        title: 'Folder upload',
        icon: FolderIcon,
        folderInput: true,
        disabled: !isUserCurrentEditor,
        onChange: async (event: React.ChangeEvent<HTMLInputElement>) => {
          let files = Array.from(event?.target?.files || []).map((file) => {
            // Create a new File object with the full path in the name
            const fullPath = '/' + file.webkitRelativePath || file.name;
            return new File([file], fullPath, { type: file.type });
          });
          files = files.filter((file) => ACCEPTED_TYPES.includes(file.type));
          setFilesToUpload(files);
          setViewState('ready');
          setIsUploadDialogOpen(true);
        },
      },
      {
        title: 'New folder',
        icon: NewFolder,
        onClick: () => setIsFolderDialogOpen(true),
        disabled: !isUserCurrentEditor,
      },
    ];
  }, [isUserCurrentEditor, openFilePicker]);

  return (
    <div>
      <Dropdown
        title="New"
        icon={AddNewIcon}
        options={options}
        testId="add-new-button"
        isButtonDisabled={false}
        resizeAction={true}
      />
      {isUploadDialogOpen && (
        <ConfirmationDialog
          title={'Upload Documents?'}
          submitButtonText={'Submit'}
          isOpen={isUploadDialogOpen}
          closeDialog={closeDialog}
          onSubmit={onUploadSubmit}
          onCancel={reset}
          viewState={viewState}
        />
      )}
      {isFolderDialogOpen && (
        <ConfirmNewFolderDialog
          clientMatterNumber={clientMatterNumber}
          clientNumber={clientNumber}
          clientMatterId={clientMatterId}
          isDialogOpen={isFolderDialogOpen}
          closeDialog={closeDialog}
        />
      )}
    </div>
  );
};
