import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import DropDownArrow from '@/assets/icons/drop-down-arrow.svg';

interface InviteOptionDropdownProps {
  removeUserInvite: () => void;
}

export const InviteOptionDropdown = ({ removeUserInvite }: InviteOptionDropdownProps) => {
  const inviteOptions = [
    { optionName: 'Admin', onClick: () => {} },
    {
      optionName: 'Remove',
      onClick: () => {
        removeUserInvite();
      },
    },
  ];
  return (
    <Menu as="div">
      {({ open }) => (
        <div className="relative flex justify-center">
          <MenuButton
            className={`${open && 'bg-[#353336]'} flex h-[28px] w-[69px] items-center rounded-[28px] bg-[#292829] py-2 pl-3 pr-[6px] text-[10px] hover:bg-[#353336]`}
          >
            <span>Admin</span>
            <img src={DropDownArrow} />
          </MenuButton>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems className="absolute right-0 top-8 z-10 w-[175px] rounded-[8px] border border-N700 bg-[#121112] text-[12px] font-medium">
              <h2 className="border-b border-N700 p-[10px] text-N400">{`Set user's rights`}</h2>
              <div className="flex flex-col p-1">
                {inviteOptions.map((option, index) => {
                  return (
                    <div key={index}>
                      <MenuItem
                        as="div"
                        className="flex h-[36px] cursor-pointer items-center rounded-[4px] px-2 hover:bg-[#292829]"
                        onClick={option.onClick}
                      >
                        {option.optionName}
                      </MenuItem>
                      {index !== inviteOptions.length - 1 && (
                        <div className="my-1 border border-N600" />
                      )}
                    </div>
                  );
                })}
              </div>
            </MenuItems>
          </Transition>
        </div>
      )}
    </Menu>
  );
};
