import { Dialog, DialogPanel } from '@headlessui/react';
import { DataRoomFile } from '@server/modules/client-matter/common/types';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { useCallback, useEffect, useMemo, useState } from 'react';

import StatusIcon from '@/assets/icons/cc-status-icon.svg';
import CopyIcon from '@/assets/icons/copy-to-clipboard.svg';
import EyeOpenIcon from '@/assets/icons/eye.svg';
import EyeClosedIcon from '@/assets/icons/eye-off.svg';
import InfoIcon from '@/assets/icons/information-icon.svg';
import Quote from '@/assets/icons/quote.svg';
import Arrow from '@/assets/images/arrow-up.svg';
import ExportIcon from '@/assets/images/export.svg';
import InformationIcon from '@/assets/images/information-icon.svg';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { useOverview } from '@/pages/overview/common/utils';
import {
  columnInformation,
  columnVisibilityType,
  MaClausesData,
} from '@/pages/overview/contract-clauses/common/utils';
import { ColumnSelector } from '@/pages/overview/contract-clauses/content/ColumnSelector';
import { MaClauseStatusDropDown } from '@/pages/overview/contract-clauses/content/MaClauseStatusDropDown';
import { ResponseIconDisplay } from '@/pages/overview/contract-clauses/content/ResponseIconDisplay';
import { SlideInPdfViewer } from '@/pages/pdf-viewer/SlideInPdfViewer';
import { showSuccessToast } from '@/utils/toasts/ToastSuccess';
import { trpcReact } from '@/utils/trpc';

const deafultContractData: MaClausesData[] = [];

const columnHelper = createColumnHelper<MaClausesData>();

export const ContractClausesContent = () => {
  const { matter, setIsPanelCollapsed, isPanelCollapsed } = useOverview();
  const { selectedFile, setSelectedFile, dataRoomFiles } = useDataRoom();
  const [isSlideViewerOpen, setIsSlideViewerOpen] = useState(false);
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
  const [isInfoIconHovered, setIsInfoIconHovered] = useState(false);
  const [showExplanations, setShowExplanations] = useState(false);
  const [focusedColumnHeader, setFocusedColumnHeader] = useState('');
  const [selectedHighlight, setSelectedHighlight] = useState<number[]>([]);
  const [selectedCell, setSelectedCell] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<number>();
  const [selectedCitation, setSelectedCitation] = useState<string | undefined>();
  const [showCitationModal, setShowCitationModal] = useState<boolean>(false);
  const [data, setContractData] = useState(() => [...deafultContractData]);
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'sourceDocument_displayName', desc: false },
  ]);
  const [columnVisibility, setColumnVisibility] = useState<columnVisibilityType>({
    Status: true,
    Description: true,
    'Expiration Date': true,
    'Renewal Period': true,
    'Notice to Terminate Renewal': true,
    'Termination for Convenience': true,
    'Anti-Assignment': true,
    'Change of Control': true,
    'Non-Compete': true,
    Exclusivity: true,
    'Non-Solicit of Customers': true,
    'ROFO/ ROFR/ ROFN': true,
    'Most Favored Nation': true,
    'Governing Law': true,
  });

  const user = trpcReact.user.getCurrentUser.useQuery().data;

  const selectAndShowCitation = useCallback((citation: string) => {
    setSelectedCitation(citation);
    setShowCitationModal(true);
  }, []);

  const selectSourceFile = useCallback(
    (fileName: string, highlight: number[], cellId: string, rowIndex: number) => {
      const toSelect = dataRoomFiles.find((file) => {
        return file.name === fileName;
      });
      if (toSelect && highlight.length > 0) {
        setSelectedFile(toSelect.name);
        setSelectedHighlight(highlight);
        setIsSlideViewerOpen(true);
        setIsPanelCollapsed(true);
        setSelectedCell(cellId);
        setSelectedRow(rowIndex);
      }
    },
    [dataRoomFiles, setIsPanelCollapsed, setSelectedFile],
  );

  type Dict<TType> = {
    [x: string]: TType | undefined;
  };

  const lowerCaseResponseToColorMap = useMemo(() => {
    return {
      no: 'text-[#30b0c7]',
      'explicitly permitted': 'text-[#30b0c7]',
      yes: 'text-[#fb7900]',
      'further review required': 'text-[#ffcc00]',
    } as Dict<string>;
  }, []);

  const checkForIconDisplay = useCallback((cellResponse: string | undefined) => {
    const validResponsesForIcon = [
      'Yes',
      'No',
      'Further Review Required',
      'Explicitly Permitted',
      'No - ROFO-ROFR-ROFN',
      'No - Customer Non-Solicit',
    ];

    if (cellResponse) {
      return validResponsesForIcon.includes(cellResponse);
    }
    return false;
  }, []);

  const createContractClauseColumn = useCallback(
    (key: string, header: string, wideCol?: boolean, iconCol?: boolean) =>
      columnHelper.accessor((row) => row.maClauses.find((c) => c.category === key), {
        header,
        enableSorting: true,
        enableHiding: true,
        sortingFn: (rowA, rowB, columnId) => {
          const valueA = rowA.getValue(columnId) as { response?: string };
          const valueB = rowB.getValue(columnId) as { response?: string };

          const responseA = valueA?.response || '';
          const responseB = valueB?.response || '';

          return responseA.localeCompare(responseB);
        },
        cell: (info) => {
          return (
            <div
              onClick={() => {
                selectSourceFile(
                  info.row.original.sourceDocument.name,
                  info?.getValue()?.locations || [],
                  info.cell.id,
                  info.row.index,
                );
              }}
              className={`static p-4 ${showExplanations && info.getValue()?.summary !== '' && 'w-[900px]'} ${wideCol ? 'w-[366px]' : ''} h-full max-h-[120px]`}
            >
              <div
                className="absolute right-[8px] top-[8px] hidden group-hover:inline-block"
                onClick={(e) => {
                  e.stopPropagation();
                  selectAndShowCitation(info?.getValue()?.citation || '');
                }}
              >
                <div className="inline-flex size-[20px] origin-top-left items-center justify-center rounded border border-[#282728] hover:bg-black">
                  <div className="relative size-[16px]">
                    <img className="size-4 pl-px" src={Quote} />
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center gap-2">
                {iconCol ? (
                  <ResponseIconDisplay
                    response={info.getValue()?.response}
                    showExplanations={showExplanations}
                  />
                ) : (
                  <></>
                )}
                <p>
                  {iconCol && checkForIconDisplay(info?.getValue()?.response)
                    ? ''
                    : info?.getValue()?.response}
                </p>
                {showExplanations && info?.getValue()?.summary.length ? (
                  <div className="flex flex-col">
                    <p
                      className={`font-['Inter'] ${
                        iconCol
                          ? lowerCaseResponseToColorMap[
                              info?.getValue()?.response?.toLowerCase() || ''
                            ]
                          : ''
                      } font-bold leading-none`}
                    >
                      {info?.getValue()?.response},
                    </p>
                    <p className="font-['Inter'] text-xs font-normal leading-none">
                      {info?.getValue()?.summary}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        },
      }),
    [
      checkForIconDisplay,
      lowerCaseResponseToColorMap,
      selectAndShowCitation,
      selectSourceFile,
      showExplanations,
    ],
  );

  const columns = useMemo(() => {
    const cols = [
      columnHelper.accessor('sourceDocument.maClausesStatus', {
        id: 'Status',
        header: 'Status',
        enableHiding: true,
        cell: (info) => {
          return (
            <MaClauseStatusDropDown
              status={info.getValue()}
              sourceDocument={info.row.original.sourceDocument.name}
            />
          );
        },
      }),
      columnHelper.accessor('sourceDocument.displayName', {
        header: 'File Name',
        enableSorting: true,
        sortingFn: 'alphanumeric',
        enableHiding: false,
        cell: (info) => {
          return (
            <div
              onClick={() => {
                selectSourceFile(
                  info.row.original.sourceDocument.name,
                  [0, 0, 0, 0, 0],
                  info.cell.id,
                  info.row.index,
                );
              }}
              className="w-[266px] p-4"
            >
              <p>{info.getValue()}</p>
            </div>
          );
        },
      }),
      createContractClauseColumn('description', 'Description', true),
      createContractClauseColumn('expiration_date', 'Expiration Date'),
      createContractClauseColumn('renewal_term', 'Renewal Period'),
      createContractClauseColumn('notice_to_terminate_renewal', 'Notice to Terminate Renewal'),
      createContractClauseColumn(
        'termination_for_convenience',
        'Termination for Convenience',
        showExplanations,
        true,
      ),
      createContractClauseColumn('anti_assignment', 'Anti-Assignment', showExplanations, true),
      createContractClauseColumn('change_of_control', 'Change of Control', showExplanations, true),
      createContractClauseColumn('non_compete', 'Non-Compete', showExplanations, true),
      createContractClauseColumn('exclusivity', 'Exclusivity', showExplanations, true),
      createContractClauseColumn(
        'no_solicit_of_customers',
        'Non-Solicit of Customers',
        showExplanations,
        true,
      ),
      createContractClauseColumn('rofo_rofr_rofn', 'ROFO/ ROFR/ ROFN', showExplanations, true),
      createContractClauseColumn(
        'most_favored_nation',
        'Most Favored Nation',
        showExplanations,
        true,
      ),
      createContractClauseColumn('governing_law', 'Governing Law'),
    ];
    return cols;
  }, [createContractClauseColumn, selectSourceFile, showExplanations]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnVisibility,
    },
    onSortingChange: setSorting,
  });

  useEffect(() => {
    let rows = [] as MaClausesData[];
    rows = matter.dataRoomFiles
      .map((file) => {
        return file.maClausesData.map((contractClause) => {
          return {
            id: contractClause.id,
            sourceDocument: file as DataRoomFile,
            maClauses: contractClause.maClauses.map((clause) => {
              return {
                category: clause.category,
                response: clause.response,
                citation: clause.citation,
                locations: clause.locations,
                summary: clause.summary,
              };
            }),
          };
        });
      })
      .flat();
    setContractData(rows);
  }, [matter.dataRoomFiles]);

  const getCellLocation = (cellFocus: MaClausesData | unknown, columnId: string) => {
    if (columnId === 'sourceDocument_displayName') {
      return true;
    }

    if (
      typeof cellFocus === 'object' &&
      cellFocus !== null &&
      'locations' in cellFocus &&
      Array.isArray((cellFocus as { locations: number[] }).locations)
    ) {
      const clauseLocation = (cellFocus as { locations: number[] }).locations;
      return clauseLocation.length > 0;
    } else {
      return null;
    }
  };

  const closeSlideInViewer = useCallback(() => {
    setSelectedCell('');
    setIsSlideViewerOpen(false);
  }, []);

  const closeDialog = useCallback(() => {
    setIsInfoDialogOpen(false);
  }, []);

  const handleSortToggle = (columnId: string) => {
    if (columnId === 'Status') return;
    setSorting((currentSorting) => {
      const existingSort = currentSorting.find((sort) => sort.id === columnId);
      if (!existingSort) {
        return [{ id: columnId, desc: false }];
      }
      return [{ id: columnId, desc: !existingSort.desc }];
    });
  };

  const displayColumnInfo = (columnId: string) => {
    const foundColumnInfo = columnInformation.find((column) => column.columnTitle === columnId);
    return foundColumnInfo?.columnInfo;
  };

  const checkForColumnInfo = (columnId: string) => {
    if (
      columnId === 'sourceDocument_displayName' ||
      columnId === 'Description' ||
      columnId === 'Status'
    )
      return false;
    return true;
  };

  const copySelectedCitation = useCallback(() => {
    navigator.clipboard.writeText(selectedCitation || '').then(() => {
      showSuccessToast('Copied!');
    });
  }, [selectedCitation]);

  const downloadTableAsExcel = useCallback(async () => {
    fetch('/Contract Clauses - Excel Example.xlsx')
      .then((response) => {
        return response.arrayBuffer();
      })
      .then(async (arrayBuffer) => {
        // Create a template
        const workbook = new Workbook();
        await workbook.xlsx.load(arrayBuffer);

        const sheet = workbook.worksheets[0];

        const client = sheet.getCell('D2');
        client.value = matter.client.name;
        const date = sheet.getCell('D3');
        date.value = new Date().toDateString();
        const generatedBy = sheet.getCell('D4');
        generatedBy.value = user?.firstName + ' ' + user?.lastName;

        const tableColumns = [
          'Filename - Response',
          'Description - Response',
          'Agreement Name - Response',
          'Agreement Name - Citation',
          'Parties - Response',
          'Parties - Citation',
          'Agreement Date - Response',
          'Agreement Date - Citation',
          'Effective Date - Response',
          'Effective Date - Citation',
          'Expiration Date - Response',
          'Expiration Date - Citation',
          'Renewal Period - Response',
          'Renewal Period - Citation',
          'Notice to Terminate Renewal - Response',
          'Notice to Terminate Renewal - Citation',
          'Termination for Convenience - Response',
          'Termination for Convenience - Summary',
          'Termination for Convenience - Citation',
          'Anti-Assignment - Response',
          'Anti-Assignment - Summary',
          'Anti-Assignment - Citation',
          'Change of Control - Response',
          'Change of Control - Summary',
          'Change of Control - Citation',
          'Non-Compete - Response',
          'Non-Compete - Summary',
          'Non-Compete - Citation',
          'Exclusivity - Response',
          'Exclusivity - Summary',
          'Exclusivity - Citation',
          'Customer Non-Solicit - Response',
          'Customer Non-Solicit - Summary',
          'Customer Non-Solicit - Citation',
          'ROFO / ROFR / ROFN - Response',
          'ROFO / ROFR / ROFN - Summary',
          'ROFO / ROFR / ROFN - Citation',
          'Most Favored Nation - Response',
          'Most Favored Nation - Summary',
          'Most Favored Nation - Citation',
          'Governing Law - Response',
          'Governing Law - Citation',
          ' ',
        ].map((name) => {
          return {
            name: name,
            filterButton: true,
          };
        });

        sheet.addTable({
          name: 'MyTable',
          ref: 'B7',
          headerRow: true,
          totalsRow: false,
          style: {
            theme: 'TableStyleLight1',
          },
          columns: tableColumns,
          rows: data.map((row) => {
            return [
              row.sourceDocument.displayName,
              row.maClauses.find((c) => c.category === 'description')?.response,
              row.maClauses.find((c) => c.category === 'agreement_name')?.response,
              row.maClauses.find((c) => c.category === 'agreement_name')?.citation,
              row.maClauses.find((c) => c.category === 'parties')?.response,
              row.maClauses.find((c) => c.category === 'parties')?.citation,
              row.maClauses.find((c) => c.category === 'agreement_date')?.response,
              row.maClauses.find((c) => c.category === 'agreement_date')?.citation,
              row.maClauses.find((c) => c.category === 'effective_date')?.response,
              row.maClauses.find((c) => c.category === 'effective_date')?.citation,
              row.maClauses.find((c) => c.category === 'expiration_date')?.response,
              row.maClauses.find((c) => c.category === 'expiration_date')?.citation,
              row.maClauses.find((c) => c.category === 'renewal_term')?.response,
              row.maClauses.find((c) => c.category === 'renewal_term')?.citation,
              row.maClauses.find((c) => c.category === 'notice_to_terminate_renewal')?.response,
              row.maClauses.find((c) => c.category === 'notice_to_terminate_renewal')?.citation,
              row.maClauses.find((c) => c.category === 'termination_for_convenience')?.response,
              row.maClauses.find((c) => c.category === 'termination_for_convenience')?.summary,
              row.maClauses.find((c) => c.category === 'termination_for_convenience')?.citation,
              row.maClauses.find((c) => c.category === 'anti_assignment')?.response,
              row.maClauses.find((c) => c.category === 'anti_assignment')?.summary,
              row.maClauses.find((c) => c.category === 'anti_assignment')?.citation,
              row.maClauses.find((c) => c.category === 'change_of_control')?.response,
              row.maClauses.find((c) => c.category === 'change_of_control')?.summary,
              row.maClauses.find((c) => c.category === 'change_of_control')?.citation,
              row.maClauses.find((c) => c.category === 'non_compete')?.response,
              row.maClauses.find((c) => c.category === 'non_compete')?.summary,
              row.maClauses.find((c) => c.category === 'non_compete')?.citation,
              row.maClauses.find((c) => c.category === 'exclusivity')?.response,
              row.maClauses.find((c) => c.category === 'exclusivity')?.summary,
              row.maClauses.find((c) => c.category === 'exclusivity')?.citation,
              row.maClauses.find((c) => c.category === 'no_solicit_of_customers')?.response,
              row.maClauses.find((c) => c.category === 'no_solicit_of_customers')?.summary,
              row.maClauses.find((c) => c.category === 'no_solicit_of_customers')?.citation,
              row.maClauses.find((c) => c.category === 'rofo_rofr_rofn')?.response,
              row.maClauses.find((c) => c.category === 'rofo_rofr_rofn')?.summary,
              row.maClauses.find((c) => c.category === 'rofo_rofr_rofn')?.citation,
              row.maClauses.find((c) => c.category === 'most_favored_nation')?.response,
              row.maClauses.find((c) => c.category === 'most_favored_nation')?.summary,
              row.maClauses.find((c) => c.category === 'most_favored_nation')?.citation,
              row.maClauses.find((c) => c.category === 'governing_law')?.response,
              row.maClauses.find((c) => c.category === 'governing_law')?.citation,
              ' ',
            ];
          }),
        });

        const buffer = await workbook.xlsx.writeBuffer();

        saveAs(
          new Blob([buffer]),
          `${new Date().toDateString()} ${matter.client.name} Contract Clauses.xlsx`,
        );
      });
  }, [data, matter.client.name, user?.firstName, user?.lastName]);

  return (
    <>
      <div
        className={`flex flex-col items-center rounded-[12px] bg-marveri-background text-[12px] text-marveri-white`}
        data-testid="contract-clause-content"
      >
        <div className="pl-[16px]">
          <div className="flex gap-2">
            <h1 className="mt-[16px] text-[34px] font-bold">Contract Clauses</h1>
            <img
              src={InformationIcon}
              onClick={() => setIsInfoDialogOpen(true)}
              className="size-5 translate-y-[2.2rem] cursor-pointer"
            />
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex gap-2">
              <ColumnSelector
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
              />
              <div
                onClick={() => setShowExplanations((current) => !current)}
                className={`${showExplanations ? 'bg-[#302e30]' : ''} inline-flex h-[30px] cursor-pointer items-center justify-center gap-1 rounded border border-[#302e30] px-3 py-2.5`}
              >
                <div className={`${showExplanations && 'translate-y-[2px]'} relative size-4`}>
                  <img src={showExplanations ? EyeOpenIcon : EyeClosedIcon} />
                </div>
                <div className="text-center font-['Inter'] text-xs font-semibold leading-snug text-[#e9e5ea]">
                  Explanations
                </div>
              </div>
            </div>
            <div
              onClick={downloadTableAsExcel}
              className="inline-flex h-[30px] cursor-pointer items-center justify-center gap-1 rounded border border-[#302e30] px-3 py-2.5"
            >
              <div className="relative mr-1 size-4">
                <div className="relative size-4">
                  <img src={ExportIcon} />
                </div>
              </div>
              <div className="text-center font-['Inter'] text-xs font-semibold leading-snug text-[#e9e5ea]">
                Export full table
              </div>
            </div>
          </div>
          <div
            className={`mb-2 mt-[10px] rounded-r-[8px] rounded-bl-[8px] border border-[#292829] p-[4px]`}
            data-testid="contract-clause-content-list"
          >
            <ScrollableDiv
              containerStyle={`${isSlideViewerOpen ? 'w-[calc(100vw-765px)]' : isPanelCollapsed ? 'w-[calc(100vw-130px)]' : 'w-[calc(100vw-320px)]'} h-[calc(100vh-194px)] transition-all duration-300`}
            >
              <table className="min-w-full text-left">
                <thead className="sticky top-0 z-10 border-b border-[#121112] bg-[#19181A] font-medium">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          className={`${
                            header.column.getCanSort() ? 'cursor-pointer' : ''
                          } ${header.column.id === 'Status' ? 'min-w-[56px]' : 'min-w-[147px] border-r border-[#121112]'} group relative h-[107px] min-w-[147px] select-none py-4 text-N300 hover:text-N200`}
                          onClick={() => handleSortToggle(header.column.id)}
                          onMouseEnter={() => setFocusedColumnHeader(header.column.id)}
                        >
                          <div className="flex  flex-row items-center px-[12px] py-[10px]">
                            {header.column.id === 'Status' ? (
                              <img src={StatusIcon} className="translate-x-[5.5px]" />
                            ) : (
                              <span>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.header, header.getContext())}
                              </span>
                            )}
                            {header.column.id !== 'Status' && (
                              <span className="absolute bottom-2 left-2 flex text-[12px] font-medium">
                                {header.column.getIsSorted() === 'asc' ? (
                                  <img className="group mr-1 align-middle" src={Arrow} />
                                ) : header.column.getIsSorted() === 'desc' ? (
                                  <img className="mr-1 rotate-180 align-middle" src={Arrow} />
                                ) : (
                                  <img
                                    className="group mr-1 hidden align-middle opacity-50 group-hover:block"
                                    src={Arrow}
                                  />
                                )}
                              </span>
                            )}
                            {checkForColumnInfo(header.column.id) && (
                              <div
                                className="absolute left-2 top-2 hidden group-hover:flex hover:filter-icon-hover"
                                onMouseEnter={() => setIsInfoIconHovered(true)}
                                onMouseLeave={() => setIsInfoIconHovered(false)}
                              >
                                <img src={InfoIcon} className="size-[16px]" />
                              </div>
                            )}
                          </div>
                          {isInfoIconHovered && header.column.id === focusedColumnHeader && (
                            <div className="absolute right-[58px] top-6 z-10 w-[280px] translate-x-[-60px] rounded-[8px] border border-[#292829] bg-[#19181A] px-[16px] py-[12px] text-[12px] font-medium text-marveri-white">
                              {displayColumnInfo(header.column.id)}
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr
                      key={row.id}
                      className={`border-b border-black text-[12px] font-medium text-N400 hover:text-N200 ${
                        selectedRow === row.index && 'bg-[#111111] text-N200'
                      }`}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className={`min-h-[80px] hover:border-b hover:border-b-N300 hover:text-[#EAE6EA] ${focusedColumnHeader === cell.column.id && 'bg-[#ffffff02]'} ${getCellLocation(cell.getValue(), cell.column.id) ? 'group relative cursor-pointer font-medium ' : 'cursor-default'} ${selectedCell === cell.id && 'text-marveri-white ring-1 ring-inset ring-marveri-white'}`}
                          onMouseEnter={() => setFocusedColumnHeader(cell.column.id)}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </ScrollableDiv>
          </div>
        </div>
      </div>
      <Dialog
        open={isInfoDialogOpen}
        onClose={closeDialog}
        className={`${isSlideViewerOpen ? 'w-[calc(100vw-660px)]' : ''} fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50`}
      >
        <DialogPanel className="flex w-[630px] flex-col items-center justify-center rounded-[12px] border-2 border-dark-border bg-container-dark p-4 text-marveri-white">
          <ScrollableDiv>
            <p className="mr-[15px]">
              Marveri deploys a variety of AI models to identify and review every commercial
              contract uploaded, in order to identify certain clauses that could be implicated in a
              merger or other corporate transaction.
              <br></br>
              <br></br>
              Carefully review all AI output. Marveri generally meets (or exceeds) the accuracy of
              human reviewers; however, the output will not be perfect, and the mistakes made by
              Marveri may not be the same or similar to those which could occur in a human review.
              Do not rely on Marveri&apos;s output as necessarily detecting all relevant clauses,
              whether listed below or not, that you may need to identify as part of a legal review.
            </p>
            <div className="mt-4">
              <button
                className="mx-2 h-[38px] w-[100px] rounded border border-dark-border bg-container-darkest text-marveri-muted-silver hover:bg-container-hover"
                onClick={closeDialog}
              >
                OK
              </button>
            </div>
          </ScrollableDiv>
        </DialogPanel>
      </Dialog>
      <Dialog
        open={showCitationModal}
        onClose={() => setShowCitationModal(false)}
        className={`${isSlideViewerOpen ? 'w-[calc(100vw-660px)]' : ''} fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50`}
        data-testid="settings-page-modal"
      >
        <DialogPanel className="relative inline-flex max-h-[627px] items-start justify-start gap-2.5 rounded-2xl bg-[#282728] px-6 pb-6 pt-8">
          <div className="w-[501px] font-['Inter'] text-xs font-medium leading-snug text-[#cdcace]">
            “<br />
            {selectedCitation}
            <br />“
          </div>
          <div
            className="group absolute right-[24px] top-[24px] inline-flex size-5 cursor-pointer items-center justify-center p-[3.20px]"
            onClick={copySelectedCitation}
          >
            <div className="relative">
              <img src={CopyIcon} />
            </div>
            <div className="absolute right-0 top-0 z-10 hidden h-[25px] w-[120px] translate-x-[50px] translate-y-[-30px] items-start justify-center gap-1 rounded-lg border border-[#d9d9d9] bg-white p-1.5 shadow group-hover:inline-block">
              <div className="text-center font-['Inter'] text-xs font-medium leading-none text-black">
                Copy to clipboard
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
      {!!selectedFile && !!isSlideViewerOpen && (
        <SlideInPdfViewer
          title={selectedFile.displayName}
          selectedHighlight={selectedHighlight}
          originalTitle={selectedFile.name}
          closeSlideViewer={closeSlideInViewer}
        />
      )}
    </>
  );
};
