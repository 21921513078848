import { useNavigate, useParams } from 'react-router-dom';

import LoadingSpinner from '@/components/LoadingSpinner';
import { trpcReact } from '@/utils/trpc';

export const AcceptInvite = () => {
  const { clientMatterId } = useParams();
  const navigate = useNavigate();

  trpcReact.dataRoom.acceptInviteToDocumentSet.useQuery(
    {
      clientMatterId: clientMatterId || '',
      // TODO deprecate these from input schema
      clientMatterNumber: 0,
      clientNumber: 0,
    },
    {
      onSuccess: () => {
        navigate(`/${clientMatterId}/dataroom`);
      },
      onError: () => {
        navigate(`/home`);
      },
    },
  );

  return <LoadingSpinner />;
};
