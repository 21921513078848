import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { HighlightArea } from '@react-pdf-viewer/highlight';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useRef } from 'react';
import Markdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

import ExitIcon from '@/assets/icons/gray-x-icon.svg';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { ReferencesTab } from '@/pages/overview/common/side-panel/ReferencesTab';
import { SelectedFolderDisplay } from '@/pages/overview/dataroom/sidebar/SelectedFolderDisplay';

interface RightSidePanelProps {
  pdfInteraction: boolean;
  hoveredHighlight?: HighlightArea | null;
  handleJumpToHighlight?: (highlights: number[]) => void;
  handleReferenceMouseOver?: (highlights: number[]) => void;
}

interface RightSidePanelTabProps {
  label: string;
}

const RightSidePanelTab = ({ label }: RightSidePanelTabProps) => (
  <Tab as="div">
    {({ selected }) => (
      <div
        className={`flex cursor-pointer items-center justify-start gap-1 border-b-2 px-3 py-[9px] ${
          selected ? ' border-[#e9e5ea]' : 'border-transparent'
        }`}
      >
        <div
          className={` font-['Inter'] text-sm font-medium hover:text-[#e9e5ea] ${
            selected ? 'text-[#e9e5ea]' : 'text-[#a8a5a9]'
          }`}
        >
          {label}
        </div>
      </div>
    )}
  </Tab>
);

export const RightSidePanel = ({
  pdfInteraction,
  hoveredHighlight,
  handleJumpToHighlight,
  handleReferenceMouseOver,
}: RightSidePanelProps) => {
  const { selectedFile, setSelectedFile, selectedFileDetails, checkedFiles, selectedFoldersArray } =
    useDataRoom();
  const summaryDivRef = useRef<HTMLDivElement>(null);

  const flags = useFlags();

  useEffect(() => {
    if (selectedFile) {
      if (summaryDivRef.current) {
        summaryDivRef.current.scrollTop = 0;
      }
    }
  }, [selectedFile]);

  if (
    (selectedFoldersArray.length !== 0 || checkedFiles.length !== 0) &&
    selectedFile === undefined
  ) {
    return <SelectedFolderDisplay />;
  }

  const reformatEffectiveDate = (effectiveDate: string) => {
    if (effectiveDate !== 'N/A') {
      const newDate = new Date(effectiveDate);
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      newDate.setDate(newDate.getDate() + 1);
      const month = months[newDate.getMonth()];
      const day = newDate.getDate();
      const year = newDate.getFullYear();
      const formattedDate = `${month} ${day}, ${year}`;

      return formattedDate;
    }

    return 'N/A';
  };

  const calculateSummaryHeight = () => {
    if (pdfInteraction) {
      return 'h-[calc(100vh-325px)]';
    }
    return 'h-[calc(100vh-295px)]';
  };

  return (
    <>
      {selectedFileDetails && (
        <div
          className={`relative flex h-full w-[420px] shrink-0 flex-col bg-container-default pl-4 pr-1 text-marveri-white`}
          data-testid="right-panel-sidebar"
        >
          <TabGroup>
            {!pdfInteraction && (
              <div className="flex items-end justify-end">
                <MarveriIcon
                  icon={ExitIcon}
                  iconStyle=""
                  iconType="other"
                  onClick={() => setSelectedFile('')}
                />
              </div>
            )}
            <TabList className={`${pdfInteraction ? 'mt-[15px]' : 'mt-[5px]'}`}>
              <div className="mb-6 inline-flex h-9 w-full items-center justify-between px-2 font-medium">
                <RightSidePanelTab label="Summary" />
                <RightSidePanelTab label="References" />
                {flags.notesFeature && <RightSidePanelTab label="Notes" />}
              </div>
            </TabList>
            <TabPanels>
              <TabPanel>
                <section className="flex flex-col justify-center gap-6 border-b border-b-light-border pb-6">
                  <div className="flex flex-col gap-1">
                    <h3 className="text-[12px] font-bold text-marveri-light-silver">TYPE</h3>
                    <p className="text-[14px] font-medium">{selectedFileDetails.type}</p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <h3 className="text-[12px] font-bold text-marveri-light-silver">
                      EFFECTIVE DATE
                    </h3>
                    <p className="text-[14px] font-medium">
                      {reformatEffectiveDate(selectedFileDetails.effective_date)}
                    </p>
                  </div>
                </section>
                <div className={'flex h-full flex-col justify-between'}>
                  <section>
                    <div>
                      <h3 className="mt-6 text-[12px] font-bold text-marveri-light-silver">
                        SUMMARY
                      </h3>
                      <ScrollableDiv
                        containerStyle={`${calculateSummaryHeight()} ${
                          pdfInteraction ? 'min-h-[80px]' : 'min-h-[150px]'
                        } pr-2 text-[12px] font-normal scrollbar-thumb-[#403E40]`}
                        ref={summaryDivRef}
                      >
                        <Markdown
                          remarkPlugins={[remarkBreaks]}
                          // @ts-expect-error markdown expects non-generic elements
                          components={{
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            h1: ({ node, ...props }) => (
                              <h2
                                className="font-['Inter'] text-[20px] font-semibold leading-tight text-[#e9e5ea]"
                                {...props}
                              />
                            ),
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            h2: ({ node, ...props }) => (
                              <h2
                                className="mb-2 mt-3 font-['Inter'] text-[14px] font-medium leading-tight text-[#e9e5ea]"
                                {...props}
                              />
                            ),
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            h3: ({ node, ...props }) => (
                              <h2
                                className="font-['Inter'] text-[12px] font-semibold leading-tight text-[#e9e5ea]"
                                {...props}
                              />
                            ),
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            h4: ({ node, ...props }) => (
                              <h2
                                className="font-['Inter'] text-[12px] font-medium leading-tight text-[#e9e5ea]"
                                {...props}
                              />
                            ),
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            p: ({ node, ...props }) => (
                              <p
                                className="font-['Inter'] text-[12px] font-normal leading-tight text-[#e9e5ea]"
                                {...props}
                              />
                            ),
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            ul: ({ node, ...props }) => (
                              <ul
                                className="ml-5 list-disc font-['Inter'] text-[12px] font-normal leading-tight text-[#e9e5ea]"
                                {...props}
                              />
                            ),
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            ol: ({ node, ...props }) => (
                              <ol
                                className="ml-5 list-decimal font-['Inter'] text-[12px] font-normal leading-tight text-[#e9e5ea]"
                                {...props}
                              />
                            ),
                          }}
                        >
                          {selectedFileDetails.summary}
                        </Markdown>
                        {selectedFileDetails.signatories &&
                          selectedFileDetails.signatories.length > 0 && (
                            <section className="py-3">
                              <div className="inline-flex h-[15px] w-full">
                                <h3 className="mt-6 text-[12px] font-bold text-marveri-light-silver">
                                  SIGNATORIES
                                </h3>
                              </div>
                              <div className="inline-flex h-[138px] w-full flex-col items-start justify-start gap-3 py-3">
                                {selectedFileDetails.signatories.map((signatory, idx) => (
                                  <div
                                    key={idx + 'signatory'}
                                    className="inline-flex w-full items-start justify-between self-stretch"
                                  >
                                    <div className="font-['Inter'] text-sm font-medium text-[#e9e5ea]">
                                      {signatory.signatory}
                                    </div>
                                    <div className="flex items-start justify-start">
                                      <div
                                        className={`px-2.5 py-0.5 ${
                                          signatory.status ? 'bg-[#353335]' : 'bg-[#5857d0]'
                                        } flex items-center justify-center gap-2.5 rounded-[23px]`}
                                      >
                                        <div className="text-nowrap font-['Inter'] text-[10px] font-semibold leading-[14px] text-white">
                                          {signatory.status ? 'Signed' : 'Signature required'}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </section>
                          )}
                      </ScrollableDiv>
                    </div>
                  </section>
                </div>
              </TabPanel>
              <TabPanel>
                <ReferencesTab
                  pdfInteraction={pdfInteraction}
                  hoveredHighlight={hoveredHighlight}
                  handleJumpToHighlight={handleJumpToHighlight}
                  handleReferenceMouseOver={handleReferenceMouseOver}
                />
              </TabPanel>
              <TabPanel>
                <textarea
                  className={`${pdfInteraction ? 'h-[calc(100vh-150px)]' : 'h-[calc(100vh-115px)]'} w-full resize-none rounded-lg bg-[#191719] px-4 py-[10px] text-[14px] font-medium text-N400 outline-none`}
                ></textarea>
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      )}
    </>
  );
};
