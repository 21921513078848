import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FolderIcon from '@/assets/icons/folder-icon-solid.svg';
import KebabIcon from '@/assets/icons/kebab-icon.svg';
import UserIcon from '@/assets/icons/user-icon-solid.svg';
import DeleteIcon from '@/assets/images/delete-icon.png';
import { DeleteDiligence } from '@/pages/home/content/DeleteDiligenceModal';

export interface DocumentSetCardProps {
  matterItem: {
    clientName: string | null;
    clientNumber: number;
    number: number;
    numberOfFiles: number;
    index: number;
    id: string;
    sharedEmailCount: number;
    createdBy?: string;
  };
  userEmail?: string;
}

export const DocumentSetCard = ({ matterItem, userEmail }: DocumentSetCardProps) => {
  const featureFlags = useFlags();
  const [isCardHovered, setIsCardHovered] = useState<boolean>(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleMouseOut = () => {
    setIsCardHovered(false);
    setIsDeleteVisible(false);
  };

  const handleMatterClicked = () => {
    localStorage.setItem('clientName', matterItem.clientName || '');
    navigate(`/${matterItem.id}/dataroom`);
  };

  const truncateLongDocumentSetTitle = useCallback(
    (title: string | null) => {
      if (title) {
        const truncatedText = title.length > 26 ? title.substring(0, 26) + '...' : title;
        if (isCardHovered) {
          const truncatedFurtherText = title.length > 26 ? title.substring(0, 20) + '...' : title;
          return truncatedFurtherText;
        }
        return truncatedText;
      }
    },
    [isCardHovered],
  );

  return (
    <div
      className="group flex h-[60px] w-[230px] py-[14px]"
      onMouseEnter={() => setIsCardHovered(true)}
      onMouseLeave={handleMouseOut}
      data-testid="document-set-card"
    >
      <div
        className="z-[1] flex size-full cursor-pointer items-center gap-[12px] text-[#CECECE] group-hover:text-marveri-white"
        onClick={handleMatterClicked}
        data-testid="matter-item"
      >
        <img src={FolderIcon} className="h-[20px]" />
        <div className="flex flex-col gap-[4px]">
          <span className="text-[14px] font-semibold" data-testid="matter-item-title">
            {truncateLongDocumentSetTitle(matterItem.clientName)}
          </span>
          <div className="flex items-center justify-between text-[11px] font-medium text-marveri-light-silver">
            <span
              className="mr-[6px] flex items-center gap-[6px]"
              data-testid="matter-item-total-files"
            >
              {matterItem.numberOfFiles} Document{matterItem.numberOfFiles > 1 && 's'}
              {matterItem.sharedEmailCount !== 0 && featureFlags.inviteMultiUser && (
                <div className="flex items-center gap-[6px]">
                  <div className="size-[2px] rounded-full bg-N500" />

                  <span className="flex gap-[4px]">
                    {matterItem.sharedEmailCount}
                    <img src={UserIcon} className="translate-y-[-0.5px]" />
                  </span>
                </div>
              )}
            </span>
          </div>
        </div>
      </div>
      <div
        className={`${
          isCardHovered && userEmail === matterItem.createdBy ? 'block' : 'hidden'
        } absolute right-0 z-[2] mx-2 translate-y-[-8px] cursor-pointer hover:filter-hover-filter`}
        onClick={() => setIsDeleteVisible((current) => !current)}
        data-testid="kebab-icon"
      >
        <img src={KebabIcon} className="m-2 h-[20px]" />
      </div>
      <div
        onClick={() => setIsDeleteModalOpen(true)}
        className={`${
          isDeleteVisible ? 'block' : 'hidden'
        } absolute right-3 z-[9999] mx-2 flex h-[35px] w-[130px] translate-y-[22px] cursor-pointer items-center rounded-[5px] border-2 border-dark-border bg-container-darkest text-[14px] hover:bg-container-hover`}
        data-testid="delete-icon"
      >
        <img src={DeleteIcon} className="m-2 h-[16px]" />
        Delete
      </div>
      <DeleteDiligence
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        setIsDeleteVisible={setIsDeleteVisible}
        matterItem={matterItem}
      />
    </div>
  );
};
