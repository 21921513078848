import { useCallback, useEffect, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import CloseIcon from '@/assets/icons/close-toast-icon.svg';
import { ClientMatter } from '@/common/types';
import LoadingSpinner from '@/components/LoadingSpinner';
import { AdminRequestType } from '@/pages/overview/ClientMatter';
import { trpcReact } from '@/utils/trpc';

const EDIT_REQUEST_WINDOW_TIME_IN_S = 5;
const EDIT_REQUEST_WINDOW_TIME_IN_MS = EDIT_REQUEST_WINDOW_TIME_IN_S * 1000;

interface RequestAccessPanelProps {
  matter: ClientMatter;
  isUserCurrentEditor: boolean;
  adminRequest: AdminRequestType | undefined;
}

export const RequestAccessPanel = ({
  matter,
  isUserCurrentEditor,
  adminRequest,
}: RequestAccessPanelProps) => {
  const [requesting, setRequesting] = useState(false);
  const [canceling, setCanceling] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [hasRequestedBefore, setHasRequestedBefore] = useState(false);

  const requestEditorRole = trpcReact.dataRoom.requestEditorRole.useMutation();
  const denyEditorRoleRequest = trpcReact.dataRoom.denyEditorRoleRequest.useMutation();
  const takeEditorRole = trpcReact.dataRoom.takeEditorRole.useMutation();

  const denyRoleRequest = useCallback(() => {
    if (adminRequest?.userId) {
      denyEditorRoleRequest.mutate({
        clientMatterId: matter.id,
        requestUserId: adminRequest?.userId,
      });
    }
  }, [adminRequest?.userId, denyEditorRoleRequest, matter.id]);

  const grantRoleRequest = useCallback(() => {
    setRequesting(true);
  }, []);

  const cancelRequest = useCallback(() => {
    setCanceling(true);
    if (timeoutId) {
      clearTimeout(timeoutId);
      setHasRequestedBefore(false);
      setRequesting(false);
    }
  }, [timeoutId]);

  const takeEditRole = useCallback(() => {
    takeEditorRole.mutate(
      {
        clientMatterId: matter.id,
      },
      {
        onSettled: () => {
          setRequesting(false);
        },
        onSuccess: () => {
          setHasRequestedBefore(false);
        },
      },
    );
  }, [matter.id, takeEditorRole]);

  const requestEditRole = useCallback(() => {
    setCanceling(false);
    setRequesting(true);
    setHasRequestedBefore(true);
    requestEditorRole.mutate(
      {
        clientMatterId: matter.id,
      },
      {
        onSuccess: () => {
          const id = setTimeout(() => {
            takeEditRole();
          }, EDIT_REQUEST_WINDOW_TIME_IN_MS);
          setTimeoutId(id);
        },
        onError: () => {
          setRequesting(false);
          setHasRequestedBefore(false);
        },
      },
    );
  }, [matter.id, requestEditorRole, takeEditRole]);

  useEffect(() => {
    setRequesting(false);
    setCanceling(false);
  }, [matter.lastAdminEditAt]);

  return (
    <div className="pointer-events-none fixed bottom-2 flex w-full justify-center">
      <div
        className={`pointer-events-auto inline-flex h-[52px] items-center justify-start gap-[83px] rounded-[37px] ${hasRequestedBefore && !requesting ? `bg-[#CF212E]` : `bg-[#545454]`} px-6 py-4`}
      >
        <div className="flex items-center justify-start gap-3">
          {isUserCurrentEditor ? (
            adminRequest && adminRequest.clientMatterId === matter.id ? (
              <>
                <div className="self-center font-['Inter'] text-xs font-medium leading-none tracking-tight text-white">
                  {adminRequest.firstName} {adminRequest.lastName} has requested to become active
                  user
                </div>
                <div onClick={denyRoleRequest} className="cursor-pointer self-center">
                  <img src={CloseIcon} />
                </div>
                <div
                  onClick={grantRoleRequest}
                  className="inline-flex h-9 cursor-pointer items-center justify-center gap-1 rounded-full border border-[#a8a5a9] p-3"
                >
                  {!requesting ? (
                    <div className="text-center font-['Inter'] text-sm font-medium leading-[18px] text-[#e9e5ea]">
                      Grant access
                    </div>
                  ) : (
                    <LoadingSpinner />
                  )}
                </div>
              </>
            ) : (
              <div className="font-['Inter'] text-xs font-medium leading-none tracking-tight text-white">
                You are the active user
              </div>
            )
          ) : (
            <>
              <div className="self-center font-['Inter'] text-xs font-medium leading-none tracking-tight text-white">
                {hasRequestedBefore && !requesting
                  ? `${matter.currentEditor?.firstName} denied your request`
                  : requesting
                    ? `Awaiting ${matter.currentEditor?.firstName}'s approval`
                    : `${matter.currentEditor?.firstName} ${matter.currentEditor?.lastName} is active`}
              </div>

              {!requesting ? (
                <div
                  onClick={requestEditRole}
                  className="inline-flex h-9 cursor-pointer items-center justify-center gap-1 rounded-full border border-[#a8a5a9] p-3"
                >
                  <div className="text-center font-['Inter'] text-sm font-medium leading-[18px] text-[#e9e5ea]">
                    {hasRequestedBefore && !requesting ? 'Request again' : 'Request access'}
                  </div>
                </div>
              ) : (
                <>
                  <CountdownCircleTimer
                    isPlaying
                    duration={EDIT_REQUEST_WINDOW_TIME_IN_S}
                    colors="#808080"
                    size={30}
                    strokeWidth={1}
                  >
                    {({ remainingTime }) => <span className="text-[#e9e5ea]">{remainingTime}</span>}
                  </CountdownCircleTimer>
                  <div
                    onClick={cancelRequest}
                    className="inline-flex h-9 cursor-pointer items-center justify-center gap-1 rounded-full border border-[#a8a5a9] p-3"
                  >
                    {!canceling ? (
                      <div className="text-center font-['Inter'] text-sm font-medium leading-[18px] text-[#e9e5ea]">
                        Cancel request
                      </div>
                    ) : (
                      <LoadingSpinner />
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
