import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { arraySwap, rectSwappingStrategy, SortableContext } from '@dnd-kit/sortable';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { formatFileDisplay, FormatTemplate } from '@/pages/overview/common/utils';
import { DraggablePlaceholder } from '@/pages/settings/namingTemplate/DraggablePlaceholder';
import { InputStateType } from '@/pages/settings/namingTemplate/NamingTemplate';

interface DragNDropTemplateProps {
  newTemplate: string;
  setNewTemplate: (val: string) => void;
  setExampleText: (val: string) => void;
  inputState: InputStateType;
  setInputState: React.Dispatch<React.SetStateAction<InputStateType>>;
  setCanClose: (val: boolean) => void;
  selectedDateFormat: string;
  setSelectedDateFormat: (val: FormatTemplate) => void;
}

export const DragNDropTemplate = ({
  newTemplate,
  setNewTemplate,
  setExampleText,
  inputState,
  setInputState,
  setCanClose,
  selectedDateFormat,
  setSelectedDateFormat,
}: DragNDropTemplateProps) => {
  const parseTemplate = useCallback((template: string) => {
    const parts = template.split(/(<<[^>>]+>>)/g);
    return parts.map((part, index) => ({
      id: part.startsWith('<<') ? part : `text-${index}`,
      content: part,
      draggable: part,
    }));
  }, []);
  const [templateParts, setTemplateParts] = useState(parseTemplate(newTemplate));
  const [activeDragId, setActiveDragId] = useState<UniqueIdentifier | null>(null);
  const [isDragActive, setIsDragActive] = useState(false);
  const [focusedInputId, setFocusedInputId] = useState('');
  const [overId, setOverId] = useState('');

  useEffect(() => {
    setTemplateParts(parseTemplate(newTemplate));
  }, [newTemplate, parseTemplate]);

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setIsDragActive(true);
    setActiveDragId(event.active.id);
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      setOverId('');
      if (over && active.id !== over.id && over.id.toString().startsWith('<<')) {
        const activeIndex = templateParts.findIndex((item) => item.id === active.id);
        const overIndex = templateParts.findIndex((item) => item.id === over.id);

        const newOrder = arraySwap(templateParts, activeIndex, overIndex);
        const newTemplateOrder = newOrder.map((template) => {
          return template.content;
        });

        const fullTemplateString = newTemplateOrder.join('');

        const formatFullTempalteString = fullTemplateString.replace(/>>(?=<<)/g, '>>');

        setTemplateParts(newOrder);
        setNewTemplate(formatFullTempalteString);
        setExampleText(
          formatFileDisplay(
            {
              date: new Date('2020-06-15T12:00:00Z'),
              company: 'Certus, Inc.',
              docTitle: 'Convertible Promissory Note',
              subject: ['Yardstick VC, LLC'],
              name: 'Does not matter.pdf',
            },
            formatFullTempalteString,
          ),
        );
        setInputState({
          ...inputState,
          saveStatus: 'UNSAVED',
        });
        setCanClose(false);
      }
      setActiveDragId(null);
      setIsDragActive(false);
    },
    [inputState, setCanClose, setExampleText, setInputState, setNewTemplate, templateParts],
  );

  const handleDragOver = useCallback((event: DragOverEvent) => {
    const { over } = event;
    setOverId(over?.id.toString() || '');
  }, []);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, inputId: string) => {
      let updatedText = '';

      if (inputId === focusedInputId) {
        const updatedInputContent = templateParts.map((part) => {
          if (part.id === focusedInputId) {
            return { ...part, content: event.target.value };
          }
          return part;
        });
        updatedText = updatedInputContent.map((content) => content.content).join('');
      }

      setNewTemplate(updatedText);
      setTemplateParts(parseTemplate(updatedText));
      setExampleText(
        formatFileDisplay(
          {
            date: new Date('2020-06-15T12:00:00Z'),
            company: 'Certus, Inc.',
            docTitle: 'Convertible Promissory Note',
            subject: ['Yardstick VC, LLC'],
            name: 'Does not matter.pdf',
          },
          updatedText,
        ),
      );
      setInputState({
        ...inputState,
        saveStatus: 'UNSAVED',
      });
      setCanClose(false);
    },
    [
      focusedInputId,
      inputState,
      parseTemplate,
      setCanClose,
      setExampleText,
      setInputState,
      setNewTemplate,
      templateParts,
    ],
  );

  const hidePlaceHolder = useCallback(
    (content: string) => {
      return isDragActive && content === activeDragId;
    },
    [activeDragId, isDragActive],
  );

  const formatContent = useMemo(() => {
    const foundContent = templateParts.find((part) => part.id === activeDragId)?.content;
    if (foundContent) {
      if (foundContent.startsWith('<<date')) {
        return {
          title: selectedDateFormat,
          style: 'bg-[#00C7BE]',
        };
      } else if (foundContent === '<<company_name>>') {
        return {
          title: 'Company Name',
          style: 'bg-[#32ADE6]',
        };
      } else if (foundContent === '<<document_title>>') {
        return {
          title: 'Document Title',
          style: 'bg-[#5856D6]',
        };
      } else if (foundContent === '<<counterparties>>') {
        return {
          title: 'Counterparties',
          style: 'bg-[#FF2D55]',
        };
      } else {
        return {
          title: foundContent,
          style: '',
        };
      }
    }
  }, [activeDragId, selectedDateFormat, templateParts]);

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      collisionDetection={closestCorners}
    >
      <SortableContext
        items={templateParts.filter((part) => part.draggable).map((part) => part.id)}
        strategy={rectSwappingStrategy}
      >
        <div className="flex w-full flex-wrap items-center rounded-[8px] border border-N500 bg-[#121212] py-[12px] pl-[12px]">
          {templateParts.map((part) => (
            <DraggablePlaceholder
              key={part.id}
              id={part.id}
              content={part.content}
              handleOnChange={handleOnChange}
              hidePlaceHolder={hidePlaceHolder}
              setFocusedInputId={setFocusedInputId}
              selectedDateFormat={selectedDateFormat}
              setSelectedDateFormat={setSelectedDateFormat}
              overMe={part.id === overId}
            />
          ))}
        </div>
      </SortableContext>
      <DragOverlay>
        {activeDragId ? (
          <div
            className={`${formatContent?.style} flex cursor-grabbing flex-nowrap items-center justify-center rounded-[8px] p-2 text-[14px] font-semibold`}
          >
            {formatContent?.title}
          </div>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};
